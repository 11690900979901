<template>
        <div class="panel-heading">
            <div class="stat">
                <div class="row">
                    <div class="col-xs-4">
                        <el-icon class=" color-success" :size="60">
                            <Coin />
                        </el-icon>
                    </div>
                    <div class="col-xs-8">
                        <small class="stat-label">今天的收入</small>
                        <h1>$655</h1>
                    </div>
                </div>
                <div class="row">
                    <div class="col-xs-6">
                        <small class="stat-label">Last Week</small>
                        <h4>$32,322</h4>
                    </div>

                    <div class="col-xs-6">
                        <small class="stat-label">Last Month</small>
                        <h4>$503,000</h4>
                    </div>
                </div>
            </div>
        </div>
</template>

<script lang="ts">
import { defineComponent, ref, reactive } from 'vue';
import { Coin } from '@element-plus/icons'
export default defineComponent({
    components: {
Coin

    },
    setup () {

        return {


        };
    },
});
</script>


<style scoped>
.col-xs-4{
  margin-top:9px ;
  

}
h1 {
    margin: 0.67em 0;
    font-size: 2em;
}
@media print {
    * {
        text-shadow: none !important;
        background: transparent !important;
        -webkit-box-shadow: none !important;
        box-shadow: none !important;
    }
}
* {
  color: azure;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}
h1 {
  color: azure;
    margin-top: -3px;
    margin-bottom: 10px;
     font-size: 36px;
}
h4 {
   color: azure;
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 18px;
    line-height: 24px;
}
.row {
    margin-right: -15px;
    margin-left: -15px;
}
.col-xs-4,
.col-xs-6,
.col-xs-8 {
    float: left;
}
.col-xs-6 {
    width: 40%;
}
.col-xs-4 {
    width: 33.33333333%;
}
.panel-heading {
    padding: 10px 15px;
    border-bottom: 1px solid transparent;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
}
.row:before,
.row:after {
    display: table;
    content: ' ';
}
.row:after {
    clear: both;
}
.row {
    margin-left: -10px;
    margin-right: -10px;
}
.col-xs-4,
.col-xs-6,
.col-xs-8 {
    padding-left: 10px;
    padding-right: 10px;
}
.panel-heading {
    padding:6px 20px;
}
.panel-heading {
    background-color:rgb(28, 175, 154);
}

</style>