<template>
    <div id="container" class="pt-2" style="padding:10px"></div>
</template>

<script lang="ts">
import { defineComponent, onMounted } from 'vue';

import { Datum, Pie, } from '@antv/g2plot';

export default defineComponent({
    components: {



    },
    setup (props, context) {

        const data = [
            { type: '分类一', value: 25 },
            { type: '分类二', value: 25 },
            { type: '分类三', value: 40 },
            { type: '分类四', value: 10 },

        ]
        onMounted(() => {
            const piePlot = new Pie('container', {
                appendPadding: 10,
                data,
                angleField: 'value',
                legend: {
                    position: 'bottom'
                },
                colorField: 'type',
                color: ['rgb(66, 139, 202)', 'rgb(28, 175, 154)', 'rgb(217, 83, 79)', 'rgb(240, 173, 78)'],
                radius: 1,
                innerRadius: 0.7,
                label: {
                    type: 'inner',
                    offset: '-50%',
                    content: '{value}',
                    style: {
                        textAlign: 'center',
                        fontSize: 14,
                    },
                },
                interactions: [{ type: 'element-selected' }, { type: 'element-active' }],
                statistic: {
                    title: false,

                    content: {
                        style: {
                            whiteSpace: 'pre-wrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',

                        },
                        content: 'AntV\nG2Plot',
                    },
                },
            });

            piePlot.render();
        })


        return {
            data
        };
    }
})



</script>


<style scoped>
</style>