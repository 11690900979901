<template>
    <div class="col-sm-4" style="margin-top:35px">

        <p class="mb15">服务器状态摘要</p>

        <span class="sublabel M-5">CPU Usage (40 - 32 cpus)</span>
        <div class="M-5">
            <el-progress :percentage="40" :stroke-width="15" color="rgb(66, 139, 202)"></el-progress>
        </div>

        <span class="sublabel M-5">Memory Usage (32.2%)</span>
        <div class="M-5">
            <el-progress :percentage="32" :stroke-width="15" color="rgb(28, 175, 154)"></el-progress>
        </div>

        <span class="sublabel M-5">Disk Usage (82.2%)</span>
        <div class="M-5">
            <el-progress :percentage="82" :stroke-width="15" color="rgb(217, 83, 79)"></el-progress>
        </div>

        <span class="sublabel M-5">Databases (63/100)</span>
        <div class="M-5">
            <el-progress :percentage="63" :stroke-width="15" color="rgb(240, 173, 78)"></el-progress>
        </div>

        <span class="sublabel M-5">Domains (2/10)</span>
        <div class="M-5">
            <el-progress :percentage="20" :stroke-width="15" color="rgb(28, 175, 154)"></el-progress>
        </div>

        <span class="sublabel M-5">Email Account (13/50)</span>
        <div class="M-5">
            <el-progress :percentage="26" :stroke-width="15" color="rgb(28, 175, 154)"></el-progress>
        </div>

    </div>
</template>

<script lang="ts">
import { defineComponent, onMounted } from 'vue';

import { Datum, Bar } from '@antv/g2plot';

export default defineComponent({
    components: {



    },
    setup () {

        const customColor: any = '#409eff'




        return {
            customColor
        };
    }
})



</script>


<style scoped>
.M-5 {
    margin-top: 10px;
}

* {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}
:before,
:after {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}
h5 {
    font-family: inherit;
    font-weight: 500;
    line-height: 1.1;
    color: inherit;
}
h5 {
    margin-top: 10px;
    margin-bottom: 10px;
}
h5 {
    font-size: 14px;
}
p {
    margin: 0 0 10px;
}
.col-sm-4 {
    position: relative;
}
.progress {
    height: 20px;
    margin-bottom: 20px;
    overflow: hidden;
    background-color: #f5f5f5;
    border-radius: 4px;
    -webkit-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
}
.progress-bar {
    float: left;
    width: 0;
    height: 100%;
    font-size: 12px;
    line-height: 20px;
    color: #fff;
    text-align: center;
    background-color: #428bca;
    -webkit-box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
    box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
    -webkit-transition: width 0.6s ease;
    -o-transition: width 0.6s ease;
    transition: width 0.6s ease;
}
.progress-bar-success {
    background-color: #5cb85c;
}
.progress-bar-warning {
    background-color: #f0ad4e;
}
.progress-bar-danger {
    background-color: #d9534f;
}
/*! end @import */
/*! @import http://demo.mxyhn.xyz:8020/cssthemes6/skk-0503-18/css/bootstrap-override.css */
.col-sm-4 {
    padding-left: 10px;
    padding-right: 10px;
}
.progress {
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    border-radius: 3px;
    height: 15px;
    margin-bottom: 15px;
}
.progress-bar {
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
}
.progress-bar-success {
    background-color: #1caf9a;
}
.progress-sm {
    height: 10px;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    border-radius: 2px;
}
/*! end @import */
h5 {
    font-family: 'LatoRegular', 'Lucida Grande', 'Lucida Sans Unicode',
        Helvetica, sans-serif;
}
.subtitle {
    font-size: 13px;
    text-transform: uppercase;
    color: #333;
    font-family: 'LatoBold';
    margin-bottom: 15px;
    margin-top: 0;
}
p {
    margin-bottom: 10px;
}
.sublabel {
    display: block;
    font-size: 12px;
    margin-bottom: 2px;
}
.mb5 {
    margin-bottom: 5px;
}
.mb15 {
    margin-bottom: 15px;
}
/*! CSS Used fontfaces */
</style>