
import { defineComponent, onMounted } from 'vue';

import { Datum, Bar } from '@antv/g2plot';

export default defineComponent({
    components: {



    },
    setup () {

        const customColor: any = '#409eff'




        return {
            customColor
        };
    }
})



