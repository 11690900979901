
import { defineComponent, ref, reactive } from 'vue';
import { Reading } from '@element-plus/icons'
export default defineComponent({
    components: {
Reading

    },
    setup () {

        return {


        };
    },
});
