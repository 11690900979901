
import { defineComponent, ref, reactive } from 'vue';

import { Refresh, Top, Bottom,Checked } from '@element-plus/icons'
export default defineComponent({
    components: {
        Top,
        Refresh,
        Bottom,
        Checked
    },
    setup () {

        return {

        };
    },
});
