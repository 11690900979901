<template>
    <div>
        <el-row :gutter="20">
            <el-col :span="6">
                <Car></Car>
            </el-col>
            <el-col :span="6">
                <Car2></Car2>
            </el-col>
            <el-col :span="6">
                <Car3></Car3>
            </el-col>
            <el-col :span="6">
                <Car4></Car4>
            </el-col>
        </el-row>
        <div>

            <el-row :gutter="20" style="margin-top:10px">
                <el-col :span="18">
                    <el-row :gutter="20" style="background:#fff;margin:0">
                        <el-col :span="16">
                            <MetacakeChart></MetacakeChart>
                        </el-col>
                        <el-col :span="8">
                            <BarChart></BarChart>
                        </el-col>
                    </el-row>
                </el-col>
                <el-col :span="6">
                    <div style="background:#fff">
                        <MetacakeChart2></MetacakeChart2>
                    </div>
                </el-col>
            </el-row>
        </div>
        <div style="margin-top:10px">
            <el-row :gutter="20">
                <el-col :span="24">
                    <div style="background:#fff">
                        <Biaoge></Biaoge>
                    </div>

                </el-col>
            </el-row>
        </div>

    </div>
</template>

<script lang="ts">
import { defineComponent, ref, watch } from 'vue';

import Car from './components/card.vue'

import Car2 from './components/card2.vue'
import Car3 from './components/card3.vue'
import Car4 from './components/card4.vue'
import MetacakeChart from './components/MetacakeChart.vue'
import MetacakeChart2 from './components/MetacakeChart2.vue'
import FxfCard from './components/fxfcard.vue'
import BarChart from './components/BarChart.vue'
import Biaoge from './components/biaoge.vue'
export default defineComponent({
    components: {
        Car2,
        Car3,
        Car4,
        Car,
        MetacakeChart,
        MetacakeChart2,
        BarChart,
        Biaoge,
        FxfCard

    },
    setup () {

        return {

        };
    },
});
</script>


<style scoped>
</style>