
import { defineComponent, ref, watch } from 'vue';

import Car from './components/card.vue'

import Car2 from './components/card2.vue'
import Car3 from './components/card3.vue'
import Car4 from './components/card4.vue'
import MetacakeChart from './components/MetacakeChart.vue'
import MetacakeChart2 from './components/MetacakeChart2.vue'
import FxfCard from './components/fxfcard.vue'
import BarChart from './components/BarChart.vue'
import Biaoge from './components/biaoge.vue'
export default defineComponent({
    components: {
        Car2,
        Car3,
        Car4,
        Car,
        MetacakeChart,
        MetacakeChart2,
        BarChart,
        Biaoge,
        FxfCard

    },
    setup () {

        return {

        };
    },
});
