<template>
    <div class="card alert">
        <div class="card-body">
            <div class="stat-widget-seven">
                <div class="stat-icon">
                    <i class=" pull-left"> zuo</i>
                    <div class=" pull-right">you</div>
                </div>
                <div class="stat-content">
                    <div class="stat-heading">
                        <div class="count-header">Page Views</div>
                        <div class="stat-count">9,47,570</div>
                    </div>
                    <div class="gradient-circle" id="pageviews-circle"><canvas width="100" height="100"></canvas>
                        <el-icon style=" margin: 0 auto">
                            <Checked />
                        </el-icon>
                    </div>
                    <div class="stat-footer">
                        <div class="row m-0">
                            <div class="col-lg-6 p-0 text-left">
                                <div class="analytic-arrow">
                                    <i class="ti-arrow-up">
                                        <el-icon class=" color-success">
                                            <Top />
                                        </el-icon>
                                    </i>
                                    <i class="ti-arrow-down">
                                        <el-icon class=" color-success">
                                            <Bottom />
                                        </el-icon>
                                    </i>
                                </div>
                                <div class="stat-count">3,48,420</div>
                                <div class="count-header">Average Views</div>
                            </div>
                            <div class="col-lg-6 p-0 text-right">
                                <div class="analytic-arrow">
                                    <i class="ti-arrow-up">
                                        <el-icon class=" color-success">
                                            <Top />
                                        </el-icon>
                                    </i>
                                    <i class="ti-arrow-down">
                                        <el-icon class=" color-success">
                                            <Bottom />
                                        </el-icon>
                                    </i>
                                </div>
                                <div class="stat-count">1,92,035</div>
                                <div class="count-header">Today Views</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, ref, reactive } from 'vue';

import { Refresh, Top, Bottom,Checked } from '@element-plus/icons'
export default defineComponent({
    components: {
        Top,
        Refresh,
        Bottom,
        Checked
    },
    setup () {

        return {

        };
    },
});
</script>


<style scoped>
.pull-right {
    float: right;
}
.pull-left {
    float: left;
}

* {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}
.text-left {
    text-align: left;
}
.text-right {
    text-align: right;
}
.row {
    margin-right: -15px;
    margin-left: -15px;
}
.col-lg-6 {
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
}
@media (min-width: 1200px) {
    .col-lg-6 {
        float: left;
    }
    .col-lg-6 {
        width: 50%;
    }
}
.alert {
    padding: 15px;
    margin-bottom: 20px;
    border: 1px solid transparent;
    border-radius: 4px;
}
.row:after,
.row:before {
    display: table;
    content: ' ';
}
.row:after {
    clear: both;
}
.pull-right {
    float: right !important;
}
.pull-left {
    float: left !important;
}

.m-0 {
    margin: 0px !important;
}
.p-0 {
    padding: 0px !important;
}

.card-body {
    background: #ffffff;
}
.card {
    background: #ffffff;
    margin: 7.5px 0px 7.5px 0px;
    padding: 20px;
    border-radius: 0px;
}

.stat-widget-seven .stat-heading {
    text-align: center;
}
.stat-widget-seven .gradient-circle {
    text-align: center;
    position: relative;
    margin: 30px auto;
    display: inline-block;
    width: 100%;
}
.stat-widget-seven .gradient-circle i {
    position: absolute;
    left: 0;
    right: 0;
    text-align: center;
    top: 35px;
    font-size: 30px;
}
.stat-widget-seven .stat-footer {
    text-align: center;
    margin-top: 30px;
}
.stat-widget-seven .stat-footer .stat-count {
    padding-left: 5px;
}
.stat-widget-seven .count-header {
    color: #252525;
    font-size: 12px;
    font-weight: 400;
    line-height: 30px;
}
.stat-widget-seven .stat-count {
    font-size: 18px;
    font-weight: 400;
    color: #252525;
}
.stat-widget-seven .analytic-arrow {
    position: relative;
}
.stat-widget-seven .analytic-arrow i {
    font-size: 12px;
}

.row {
    margin-left: -7.5px;
    margin-right: -7.5px;
}
.col-lg-6 {
    padding-left: 7.5px;
    padding-right: 7.5px;
}
</style>